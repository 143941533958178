import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Grid,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  FcAbout,
  FcAssistant,
  FcCollaboration,
  FcDonate,
  FcInTransit,
  FcIdea,
  FcBusinesswoman,
  FcPrivacy,
  FcManager,
} from "react-icons/fc";

const Card = ({ heading, description, icon, href }) => {
  return (
    <Box
      maxW={{ base: "full", md: "400px" }}
      w={"full"}
      //borderWidth="1px"
      borderRadius="lg"
      boxShadow={"lg"}
      backgroundColor={"cyan.100"}
      overflow="hidden"
      p={5}
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={useColorModeValue("gray.100", "gray.700")}
          mx="auto" // Add this line
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md" textAlign={"center"}>
            {heading}
          </Heading>
          <Text mt={1} fontSize={"sm"}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default function About() {
  return (
    <Box p={4} mt={10}>
      <Box width={{ base: "full", sm: "lg", lg: "xl" }} margin={"auto"}>
        <Heading
          fontSize={{ base: "2xl", sm: "4xl" }}
          fontWeight={"bold"}
          color={"blue.500"}
          textAlign={"center"}
        >
          What is hygiWay?
        </Heading>{" "}
      </Box>

      <Box
        bg={"gray.50"}
        rounded={"2xl"}
        m={{ base: 4, md: 10 }}
        shadow={"xl"}
        overflow={"hidden"}
      >
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 2fr" }}
          alignItems="center"
          justifyContent="center"
          p={5}
        >
          <Box pb="56.25%" pos="relative" gridColumn={{ md: "1 / span 1" }}
          w={{base:"", md:"45vw"}}
           >
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/7KK66wZIJJk"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                borderRadius: "10px", // set the border-radius to 10px
              }}
            ></iframe>
          </Box>
          <Stack
            flex={1}
            spacing={{ base: 5, md: 10 }}
            alignItems="center"
            justifyContent="center"
            gridColumn={{ md: "2 / span 1" }}
          >
            <Text
              color={"gray.600"}
              p={5}
              fontSize={{ base: "sm", sm: "lg" }}
              textAlign="center"
            >
              hygiWay is a technology-based, crowdsource sanitation service
              facilitator, specifically designed for Last Mile Delivery drivers.
              hygiWay directs drivers in need of a sterilized bathroom while in
              route to deliver packages. hygiWay’s application seamlessly
              integrates with our client's Last Mile Delivery network. We are
              incredibly passionate about delivering C.E.E.S.
            </Text>
          </Stack>
        </Grid>
      </Box>

      <Container maxW={"4xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={"Cleanliness"}
            icon={<Icon as={FcIdea} w={10} h={10} />}
            description={
              "hygiWay provides a cleaner option that will help protect our client’s customers from infectious diseases that can be contracted when a driver uses a public restroom, alley or urinates in a bottle. hygiWay has created partnerships within the Quick Service Restaurant (QSR) and discount retailer industries.  We believe our technology and attacks the root cause of infectious diseases and can drastically slow the spread within a community."
            }
          />
          <Card
            heading={"Equality"}
            icon={<Icon as={FcBusinesswoman} w={10} h={10} />}
            description={
              "hygiWay promotes equality by standardizing bathroom practices for all drivers in route, ensuring the same restroom opportunities for everyone. 25% of female delivery drivers avoid drinking liquids while driving because of a lack of bathrooms, compromising their health and safety for time. HygiWay's initiative creates a healthier, happier, and more productive workforce by leveling the playing field."
            }
            href={"#"}
          />
          <Card
            heading={"Efficiency"}
            icon={<Icon as={FcInTransit} w={10} h={10} />}
            description={
              "hygiWay is more efficient and will increase productivity for delivery drivers by directing them to clean, safe restrooms (Relief Stations) that are located on route instead of spending 10 to 20 minutes in search."
            }
            href={"#"}
          />
          <Card
            heading={"Safety"}
            icon={<Icon as={FcPrivacy} w={10} h={10} />}
            description={
              "hygiWay provides a much safer option for the security of the vehicles while the driver is in the restroom. This will help prevent theft of packages and damage to the vehicle."
            }
            href={"#"}
          />
        </Flex>
      </Container>
      <br></br>
      <br></br>
    </Box>
  );
}
