import React from "react";
//import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

const Header = () => {
  const navigate = useNavigate();
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const NAV_ITEMS = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "About",
      href: "/about",
    },
    {
      label: "FAQ",
      href: "/faq",
    },
  ];

  const DesktopNav = () => {
    const linkColor = "blue.500";
    const linkHoverColor = "blue.800";
    const popoverContentBgColor = useColorModeValue("white", "gray.800");
    const isMobile = useBreakpointValue({ base: true, md: false });

    if (isMobile) {
      return null;
    }

    return (
      <Stack direction={"row"} spacing={4}>
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <Link
                  p={4}
                  href={navItem.href ?? "#"}
                  fontSize={"lg"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    color: "blue.800",
                  }}
                >
                  {navItem.label}
                </Link>
              </PopoverTrigger>
            </Popover>
          </Box>
        ))}
      </Stack>
    );
  };

  const MobileNav = () => {
    return (
      <Stack p={4} display={{ md: "none" }}>
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    );
  };

  const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
      <Stack spacing={4}>
        <Flex
          py={2}
          as={Link}
          onClick={children ? onToggle : null}
          href={href}
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
        >
          <Text
            fontWeight={600}
            color={useColorModeValue("gray.600", "gray.200")}
          >
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={"all .25s ease-in-out"}
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </Flex>

        <Collapse
          in={isOpen}
          animateOpacity
          style={{ marginTop: "0!important" }}
        >
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
            align={"start"}
          >
            {children &&
              children.map((child) => (
                <Link key={child.label} py={2} href={child.href}>
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  return (
    <Box position="sticky" top="0" zIndex="sticky">
      <Flex
        bg="none"
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        backgroundColor={"#ebf8ff"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
          onClick={onToggle}
        >
          <IconButton
            icon={
              isOpen ? (
                <CloseIcon color="blue.300" w={3} h={3} />
              ) : (
                <HamburgerIcon color="blue.300" w={5} h={5} />
              )
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"Ubuntu, sans-serif"}
            fontSize={"2xl"}
            color={useColorModeValue("gray.800", "white")}
            bgGradient="linear(to-r, blue.800,blue.500)"
            bgClip="text"
            mr={1}
          >
            hygiWay
          </Text>
        </Flex>

        <Flex
          flex={{ base: 1, md: 0 }}
          justify={{ base: "center", md: "flex-end" }}
          align={"center"}
        >
          <DesktopNav />
          <Button
            variant={"solid"}
            colorScheme={"blue"}
            size={"sm"}
            ml={6}
            onClick={() => navigate("/contact")}
          >
            Contact
          </Button>
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};
export default Header;
