import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Tag,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";

const Logo = () => {
  return (
    <Text
      textAlign={useBreakpointValue({ base: "center", md: "left" })}
      fontFamily={"Ubuntu, sans-serif"}
      fontSize={"xl"}
      color={useColorModeValue("gray.800", "white")}
      bgGradient="linear(to-r, blue.800,blue.500)"
      bgClip="text"
      mr={1}
    >
      hygiWay
    </Text>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoCentered() {
  return (
    <Box color={useColorModeValue("gray.700", "gray.200")}>
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          columns={{ base: 2, sm: 2, md: 2 }}
          spacing={8}
          justifyItems="center"
        >
          <Stack align={"flex-start"}>
            <ListHeader>Company</ListHeader>
            <Link href={"about"}>About Us</Link>
            <Link href={"contact"}>Contact Us</Link>
            <Stack direction={"row"} align={"center"} spacing={2}>
              <Link
                href="http://www.hygiwaypartners.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Partners
              </Link>
              <Tag
                size={"sm"}
                bg={useColorModeValue("green.300", "green.800")}
                ml={2}
                color={"white"}
              >
                New
              </Tag>
            </Stack>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Legal</ListHeader>
            <Link href={"#"}>Cookies Policy</Link>
            <Link href={"#"}>Privacy Policy</Link>
            <Link href={"#"}>Terms of Service</Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={10} textAlign="center">
        <Flex
          align={"center"}
          justifyContent="center"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Logo />
        </Flex>
        <Text pt={6} fontSize={"sm"}>
          © 2023 hygiWay - All rights reserved
        </Text>
      </Box>
    </Box>
  );
}
