import {
  Avatar,
  Box,
  chakra,
  Container,
  Flex,
  Text,
  Heading,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

const testimonials = [
  {
    name: "What is a Relief Station?",
    content:
      "hygiWay has partnered up with Quick Service Restaurants (QSR) and discount retailers also known as Relief Stations. By utilizing one of our strategically partnered Relief Stations our client’s drivers’ risk of wasted miles, diseases, and theft are greatly reduced.",
  },
  {
    name: "How does the Driver know where to go?",
    content:
      "Drivers can access the restrooms via the hygiWay platform that is seamlessly integrated within their Last Mile Delivery/Ride Sharing network. This allows our clients to schedule restroom breaks within their routes.",
  },
  {
    name: "Who pays for the hygiWay service?",
    content:
      "hygiWay's service is provided to our clients through a monthly subscription service. We believe that a subscription-based model not only helps our clients save money, but also provides them with the flexibility to adjust their service plans as their needs evolve over time.",
  },
  {
    name: "How many Relief Stations can a driver use in one day?",
    content:
      "Each route, hygiWay will incorporate three scheduled Relief Station stops for the driver. The Relief Station stops are strategically placed for the driver within their existing route. The first scheduled stop occurs 2.5 hours after the driver departs the Distribution Center, the second would be 2 hours after the first stop and the last stop would be 3 hours from the second stop. For emergency stops, hygiWay created a “Relief Now” option.",
  },
  {
    name: "Who is responsible for keeping the Relief Station clean and safe?",
    content:
      "At the Relief Station, hygiene and safety are of the utmost importance, and hygiWay is proud to partner with reputable Quick Service Restaurants (QSR) and discount retailers to ensure that the area remains clean and safe for all visitors. Our innovative hygiene solutions, coupled with the expertise of our esteemed partners, help us maintain the highest standards of cleanliness, and provide a safe and welcoming environment for everyone who visits the Relief Station.",
  },
  {
    name: "What if a driver needs to find EMERGENCY Relief?",
    content:
      "We understand everyone at some point will need to use the restroom for an “emergency”. Delivery drivers are no different, so to prevent accidents and embarrassment hygiWay created a Relief NOW option, which directs the driver to the nearest Relief Station.",
  },
];

function TestimonialCard(props) {
    const { name, content, index } = props;
    return (
      <Flex
        boxShadow={"lg"}
        direction={{ base: "column-reverse", md: "row" }}
        width={"full"}
        rounded={"xl"}
        p={5}
        justifyContent={"space-between"}
        position={"relative"}
        bg={useColorModeValue("white", "gray.800")}
        alignItems="center"
      >
        <Flex direction={"column"}>
          <Heading
            //fontFamily={"Work Sans"}
            fontWeight={"bold"}
            fontSize={14}
            mb={4}
          >
            {name}
          </Heading>
          <Text
            //fontFamily={"Inter"}
            fontWeight={"medium"}
            fontSize={"15px"}
            maxWidth="600px"
            color="gray.600"
            mx="auto"
            textAlign={"left"}
          >
            {content}
          </Text>
        </Flex>
      </Flex>
    );
  }
  
  export default function FAQ() {
    return (
      <Flex
        textAlign={"center"}
        pt={4}
        justifyContent={"center"}
        direction={"column"}
        width={"full"}
        overflow={"hidden"}
        mt={10}
      >
        <Box width={{ base: "full", sm: "lg", lg: "xl" }} margin={"auto"}>
          <Heading
            fontSize={{ base: "2xl", sm: "4xl" }}
            fontWeight={"bold"}
            color={"blue.500"}
          >
            Frequently Asked Questions
          </Heading>{" "}
        </Box>
        <SimpleGrid
          columns={{ base: 1 }}
          spacing={"10"}
          //mt={16}
          mb={16}
          p={5}
          mx={"auto"}
        >
          {testimonials.map((cardInfo, index) => (
            <TestimonialCard {...cardInfo} index={index} />
          ))}
        </SimpleGrid>
      </Flex>
    );
  }
  