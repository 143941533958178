import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  SimpleGrid,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  IoSpeedometerOutline,
  IoWomanOutline,
  IoBugOutline,
} from "react-icons/io5";
import { ReactElement } from "react";
import { FcAssistant, FcDonate, FcInTransit } from "react-icons/fc";
import { FaGem, FaHeart, FaNetworkWired } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const FEATURES_DATA = [
  {
    title: "Dangerous",
    text: "In order to meet unrealistic deadlines, delivery drivers often risk accidents by speeding and skipping restroom breaks.",
    icon: <IoSpeedometerOutline />,
    iconColor: "yellow.500",
    backgroundColor: "yellow.200",
  },
  {
    title: "Marginalized",
    text: "Women in delivery often use makeshift methods for going to the bathroom, like using buckets and baby wipes in their vans.",
    icon: <IoWomanOutline />,
    iconColor: "red.500",
    backgroundColor: "red.200",
  },
  {
    title: "Unsanitary",
    text: "Delivery drivers often can't stop to use the restroom, resulting in unsanitary practices like urinating in bottles or on the roadside.",
    icon: <IoBugOutline />,
    iconColor: "green.500",
    backgroundColor: "green.200",
  },
];

const Feature = ({ title, text, icon, iconColor, backgroundColor }) => {
  return (
    <Stack alignItems={"center"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={iconColor}
        rounded={"full"}
        bg={backgroundColor}
        mb={1}
        fontSize={40}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"} textAlign={"center"}>
        {text}
      </Text>
    </Stack>
  );
};

export default function CallToActionWithVideo() {
  const navigate = useNavigate();
  return (
    <>
      <Container maxW={"7xl"}>
        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 10, md: 20 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            >
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "cyan.400",
                  zIndex: -1,
                }}
              >
                Deliver smarter,
              </Text>
              <br />
              <Text as={"span"} color={"cyan.400"}>
                not harder!
              </Text>
            </Heading>
            <Text color={"gray.500"}>
              Hundreds of thousands of delivery drivers are not able to
              conveniently stop to use the restroom during their routes. There
              needs to be an efficient system in place to give drivers their
              right to use the restroom.
            </Text>
            <Text color={"gray.500"}>
              The patent-pending technology of hygiWay connects delivery drivers
              with nearby Relief Stations en route to their destination,
              utilizing existing infrastructure. Relief Stations are Quick
              Service Restaurants (QSR) and discount retailers.
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: "column", sm: "row" }}
            >
              <Button
                rounded={"full"}
                size={"lg"}
                fontWeight={"normal"}
                px={6}
                colorScheme={"red"}
                bg={"cyan.400"}
                _hover={{ bg: "cyan.500" }}
                onClick={() => navigate("/about")}
              >
                Learn more
              </Button>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >
            <Box
              position={"relative"}
              height={"300px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"hidden"}
            >
              <Image
                alt={"Hero Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={
                  "https://images.unsplash.com/photo-1551825687-f9de1603ed8b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2369&q=80"
                }
              />
            </Box>
          </Flex>
        </Stack>
      </Container>

      <Box p={4}>
        <Flex align="center">
          <Box flex={1} h="2px" mr={5} ml={10} bg="blue.300" />
          <Box
            px={3}
            fontWeight="bold"
            color="blue.400"
            textTransform="uppercase"
            fontSize={{ base: "16", md: "24" }}
          >
            the current situation
          </Box>
          <Box flex={1} h="2px" ml={5} mr={10} bg="blue.300" />
        </Flex>
        <br></br>
        <br></br>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {FEATURES_DATA.map((feature) => (
            <Feature
              key={feature.title}
              title={feature.title}
              text={feature.text}
              icon={feature.icon}
              iconColor={feature.iconColor}
              backgroundColor={feature.backgroundColor}
            />
          ))}
        </SimpleGrid>
        <br></br>
        <Box
          bg={"gray.50"}
          rounded={"2xl"}
          m={{ base: 5, md: 10 }}
          shadow={"xl"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          overflow={"hidden"}
          //minH={"70vh"}
        >
          <Box
            w="100%"
            // This is the aspect ratio of the video (16:9)
            pb="56.25%"
            pos="relative"
          >
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/nFeOMGji1hY"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                borderRadius: "10px", // set the border-radius to 10px
              }}
            ></iframe>
          </Box>
        </Box>
      </Box>
      <br></br>
      <br></br>
    </>
  );
}
